// Image imports

// Education:
import Saddleback from '../Images/Saddleback.png'

// Projects:
import PeglegImg from '../Images/Projects/ProjectPegleg.png'
import PeglegGif from '../Images/Projects/pegleg.mp4'
import SocialMania from '../Images/Projects/SocialMania.png'
import WeatherApp from '../Images/Projects/weatherapp.png'
import CourseScheduler from '../Images/Projects/coursescheduler.png'
import SimpleCalculator from '../Images/Projects/simplecalculator.png'

// People:
import Trenton from '../Images/Projects/Trenton.png'
import Lucas from '../Images/Projects/Lucas.png'

export const Bio = {
    name: "Jacob Armstrong",
    roles: [
        "Software Engineer",
        "Data Scientist",
        "Full Stack Developer",
        "Programmer"
    ],
    description: "I am an Eagle Scout, and a recent graduate from California State University, Fullerton with a Bachelor's Degree in Computer Science. I am passionate, adaptable, and always willing to learn. I am detail oriented, and ensure consistent communication. I work well with teams, with experience from many group projects throughout my courses.",
    github: "https://github.com/Jacob-Armstrong",
    resume: "https://drive.google.com/file/d/1gli-ZUyrujT7HX_Ua6tvRYCm8G8uQfGy/view?usp=sharing",
    linkedin: "https://www.linkedin.com/in/jacobarmstrongcs/",
}

export const skills = [
    {
        title: "Frontend",
        skills: [
            {
                name: "HTML",
                image: "https://cdn.jsdelivr.net/gh/devicons/devicon@latest/icons/html5/html5-original.svg"
            },
            {
                name: "CSS",
                image: "https://cdn.jsdelivr.net/gh/devicons/devicon@latest/icons/css3/css3-original.svg"
            },
            {
                name: "Javascript",
                image: "https://cdn.jsdelivr.net/gh/devicons/devicon@latest/icons/javascript/javascript-original.svg"
            },
            {
                name: "React",
                image: "https://cdn.jsdelivr.net/gh/devicons/devicon@latest/icons/react/react-original.svg"
            },
            {
                name: "Figma",
                image: "https://cdn.jsdelivr.net/gh/devicons/devicon@latest/icons/figma/figma-original.svg"
            },
            {
                name: "MaterialUI",
                image: "https://cdn.jsdelivr.net/gh/devicons/devicon@latest/icons/materialui/materialui-original.svg"
            }
        ]
    },
    {
        title: "Backend",
        skills: [
            {
                name: "C++",
                image: "https://cdn.jsdelivr.net/gh/devicons/devicon@latest/icons/cplusplus/cplusplus-original.svg"
            },
            {
                name: "Java",
                image: "https://cdn.jsdelivr.net/gh/devicons/devicon@latest/icons/java/java-original.svg"
            },
            {
                name: "Python",
                image: "https://cdn.jsdelivr.net/gh/devicons/devicon@latest/icons/python/python-original.svg"
            },
            {
                name: "MySQL",
                image: "https://cdn.jsdelivr.net/gh/devicons/devicon@latest/icons/mysql/mysql-original.svg"
            },

            {
                name: "Firebase",
                image: "https://cdn.jsdelivr.net/gh/devicons/devicon@latest/icons/firebase/firebase-original.svg"
            }
        ]
    },
    {
        title: "Data Science",
        skills: [
            {
                name: "R",
                image: "https://cdn.jsdelivr.net/gh/devicons/devicon@latest/icons/r/r-original.svg"
            },
            {
                name: "RStudio",
                image: "https://cdn.jsdelivr.net/gh/devicons/devicon@latest/icons/rstudio/rstudio-original.svg"
            },
            {
                name: "ggplot2",
                image: "https://raw.githubusercontent.com/rstudio/hex-stickers/master/PNG/ggplot2.png"
            },
            {
                name: "Python",
                image: "https://cdn.jsdelivr.net/gh/devicons/devicon@latest/icons/python/python-original.svg"
            },
            {
                name: "MySQL",
                image: "https://cdn.jsdelivr.net/gh/devicons/devicon@latest/icons/mysql/mysql-original.svg"
            }
        ]
    },
    {
        title: "Game Development",
        skills: [
            {
                name: "Unity",
                image: "https://cdn.jsdelivr.net/gh/devicons/devicon@latest/icons/unity/unity-original.svg"
            },
            {
                name: "Godot",
                image: "https://cdn.jsdelivr.net/gh/devicons/devicon@latest/icons/godot/godot-original.svg"
            },
            {
                name: "C#",
                image: "https://cdn.jsdelivr.net/gh/devicons/devicon@latest/icons/csharp/csharp-original.svg"
            },
            {
                name: "GDScript",
                image: "https://godotengine.org/assets/press/icon_monochrome_dark.png"
            },
            {
                name: "Lua",
                image: "https://upload.wikimedia.org/wikipedia/commons/thumb/8/8f/Luau_Logo_%28Programming_Language%29.svg/2048px-Luau_Logo_%28Programming_Language%29.svg.png"
            }
        ]
    },
    {
        title: "Tools",
        skills: [
            {
                name: "VSCode",
                image: "https://cdn.jsdelivr.net/gh/devicons/devicon@latest/icons/vscode/vscode-original.svg"
            },
            {
                name: "Github",
                image: "https://upload.wikimedia.org/wikipedia/commons/thumb/a/ae/Github-desktop-logo-symbol.svg/1024px-Github-desktop-logo-symbol.svg.png"
            },
            {
                name: "Firefox",
                image: "https://upload.wikimedia.org/wikipedia/commons/thumb/a/a0/Firefox_logo%2C_2019.svg/1200px-Firefox_logo%2C_2019.svg.png"
            }
        ]
    }
];

export const experience = [
    {
        id: 0,
        img: "https://media.licdn.com/dms/image/v2/C560BAQE4N06TjeB7ng/company-logo_100_100/company-logo_100_100/0/1631335295494?e=1736380800&v=beta&t=0bUaNUCak479BmcF8pqy1AvTHnamYw21a6HjuEVf1wk",
        role: "E-Commerce Specialist",
        company: "Diversity IT",
        date: "Jan 2019 - Mar 2021",
        desc: "▪ Researched prices for various devices and technology\n▪ Posted listings on eBay for hundreds of different devices\n▪ Stripped servers for parts and helped with warehouse management",
        skills: [
            "Written Communication",
            "Customer Service"
        ]
    },
    {
        id: 1,
        img: "https://media.licdn.com/dms/image/v2/C4D0BAQGr9jlTchzgFQ/company-logo_100_100/company-logo_100_100/0/1631336093015?e=1736380800&v=beta&t=ysa8H3C-EvjIU2LI_gAzZSc3WigNw1rInzWmaOuo29w",
        role: "Interim Information Technology Director",
        company: "Saddleback Valley Christian Schools",
        date: "Aug 2019 - Jan 2020",
        desc: "▪ Managed IT support tickets from teachers and faculty across entire school (K-12)\n▪ Assisted teachers in the computer department with various new technologies, including 3D printers\n▪ Provided feedback about new technologies for the school to administrative department leads",
        skills: [
            "Written Communication",
            "Problem Solving",
            "Interpersonal Skills",
            "Communication",
            "Teamwork",
            "Information Technology"
        ]
    }
];

export const education = [
    {
        id: 0,
        img: "https://upload.wikimedia.org/wikipedia/commons/thumb/1/1a/California_State_University%2C_Fullerton_seal.svg/640px-California_State_University%2C_Fullerton_seal.svg.png",
        school: "California State University, Fullerton",
        degree: "Bachelor of Science - BS, Computer Science\nCum Laude",
        date: "Aug 2022 - Aug 2024",
        desc: "▪ Presented senior capstone project at the Engineering & Computer Science Student Project Innovation Expo in 2024."
    },
    {
        id: 1,
        img: Saddleback,
        school: "Saddleback College",
        degree: "Honors & IGETC, Computer Science",
        date: "Aug 2019 - May 2022",
        desc: "▪ Completed Honors Program\n▪ Completed Intersegmental General Education Transfer Curriculum (IGETC)"
    }
]

export const projects = [
    {
        id: 0,
        title: "Project Pegleg",
        date: "Jan 2024 - May 2024",
        description: `Using the Godot game engine, we created an open world, procedurally generated pirate sandbox game. \
        At any of the ports, you can buy or sell goods, hire crew, purchase a new ship, or pay off your bounty with that port's faction. \
        Every NPC ship can be fought if you want to steal their goods. If you kill too many from one faction, however, that faction's military will come after you!`,
        image: PeglegImg,
        gif: PeglegGif,
        tags: ["Godot", "GDScript"],
        category: "Game Development",
        github: "https://github.com/trenton-coggeshall/Project_Pegleg",
        webapp: "https://sleepyrogue.itch.io/project-pegleg",
        member: [
            {
                name: "Jacob Armstrong",
                img: "https://media.licdn.com/dms/image/v2/D5603AQHNAvXQMHJuBg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1728074930961?e=1733961600&v=beta&t=EoakzGjCzyvYahEQuUsRD8EEVTE2yKnsL27rvs4WJN0",
                linkedin: "https://www.linkedin.com/in/jacobarmstrongcs/",
                github: "https://github.com/Jacob-Armstrong"
            },
            {
                name: "Trenton Coggeshall",
                img: Trenton,
                linkedin: "https://www.linkedin.com/in/trenton-coggeshall-536647307/",
                github: "https://github.com/trenton-coggeshall"
            },
            {
                name: "Lucas Nguyen",
                img: Lucas,
                linkedin: "https://www.linkedin.com/in/lucas-nguyen-144093279/",
                github: "https://github.com/LucasDNguyen"
            }
        ]
    },
    {
        id: 1,
        title: "A* Maze Solver",
        date: "Apr 2024 - May 2024",
        description: `Using the Godot game engine, I created a DFS maze generator, A* Maze solver and algorithm comparison tool. \
        Using an earlier version of this tool, I discovered that A* was not much better at solving mazes than other algorthms. \
        After some testing, I created an alternate A* algorithm, labeled "Better A*", that could solve mazes without the limitation of considering steps taken.`,
        image: "https://github.com/Jacob-Armstrong/Maze-Project/raw/main/assets/Readme%20Images/maze_preview_1.png",
        gif: "https://github.com/Jacob-Armstrong/Maze-Project/raw/main/assets/Readme%20Images/maze_preview_6.gif",
        tags: ["Godot", "GDScript", "DFS", "BFS", "A*"],
        category: "Algorithm",
        github: "https://github.com/Jacob-Armstrong/Maze-Project",
        webapp: "https://maze.jacobarmstrong.dev/",
    },
    {
        id: 2,
        title: "Simple Weather App",
        date: "Apr 2024 - May 2024",
        description: `Simple Weather App is a simple web-based application for individuals who want quick temperature checks. \
        The purpose of this application is to give users information about their desired location without any unnecessary clutter. \
        Search history is stored and displayed for easy tracking and comparison. This data is then logged to a MySQL database for historical analysis. \
        The application serves as a practical example of integrating APIs, databases, and server management in a real-world application.`,
        image: WeatherApp,
        gif: "",
        tags: ["React", "Node.js", "Express", "MySQL", "Material UI", "WeatherAPI", "Axios"],
        category: "Web Development",
        github: "https://github.com/alee34161/CPSC-254-Project",
    },
    {
        id: 3,
        title: "Social Mania",
        date: "Jan 2023 - May 2023",
        description: `Using the Unity game engine, we created an idle game to simulate social media! \
        Every time you click the post button, you gain a follower and your views start to go up. \
        Once you have accumulated enough views, you can spend followers on upgrades, such as more followers per click. \
        You must maintain attention by posting in order to gain views or you will lose popularity! \
        While the game was being developed, you could sign in with your Google account to have your data saved via Firebase, but that functionality has since been disabled.`,
        image: SocialMania,
        gif: "",
        tags: ["Unity", "C#", "Firebase", "REST API"],
        category: "Game Development",
        github: "https://github.com/Jacob-Armstrong/Social-Mania",
        webapp: "https://jacobarmstrong.itch.io/social-mania",
        member: [
            {
                name: "Jacob Armstrong",
                img: "https://media.licdn.com/dms/image/v2/D5603AQHNAvXQMHJuBg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1728074930961?e=1733961600&v=beta&t=EoakzGjCzyvYahEQuUsRD8EEVTE2yKnsL27rvs4WJN0",
                linkedin: "https://www.linkedin.com/in/jacobarmstrongcs/",
                github: "https://github.com/Jacob-Armstrong"
            },
            {
                name: "Trenton Coggeshall",
                img: Trenton,
                linkedin: "https://www.linkedin.com/in/trenton-coggeshall-536647307/",
                github: "https://github.com/trenton-coggeshall"
            },
            {
                name: "Lucas Nguyen",
                img: Lucas,
                linkedin: "https://www.linkedin.com/in/lucas-nguyen-144093279/",
                github: "https://github.com/LucasDNguyen"
            }
        ]
    },
    {
        id: 4,
        title: "College Course Scheduler",
        date: "May 2021",
        description: `This program is a college course scheduler. Certain parameters are given in the input files, \
        as well as all of the user data, and scheduled courses are returned based on the students' preferred classes and teachers' availability.`,
        image: CourseScheduler,
        tags: ["Java", "Interfaces", "OOP"],
        category: "Algorithm",
        github: "https://github.com/Jacob-Armstrong/Course-Scheduler"
    },
    {
        id: 5,
        title: "SimpleCalculator",
        date: "October 2024",
        description: `A simple calculator made with HTML, CSS, and Javascript.`,
        image: SimpleCalculator,
        tags: ["HTML", "CSS", "Javascript"],
        category: "Web Development",
        github: "https://github.com/Jacob-Armstrong/SimpleCalculator",
        webapp: "https://simplecalculator.jacobarmstrong.dev/"
    }
]